import { Container } from "@components/atoms/Container";
import {
  IconGithub,
  IconInstagram,
  IconLinkedin,
  IconStackoverflow,
} from "@components/atoms/Icons";
import { SocialIcons } from "@components/molecules/SocialIcons";

const Footer = () => {
  return (
    <footer className="mt-8 py-8 md:mt-20">
      <Container className="flex flex-col-reverse gap-4 md:flex-row md:justify-between">
        <span className="text-center md:text-left">
          &copy; {new Date().getFullYear()} Mohammad Altbishat
        </span>
        <SocialIcons
          profiles={[
            {
              name: "Stackoverflow",
              url: "https://www.instagram.com/mohammad.tbeishat/",
              icon: <IconStackoverflow />,
            },
            {
              name: "Github",
              url: "https://github.com/m7mdtiger",
              icon: <IconGithub />,
            },
            {
              name: "Instagram",
              url: "https://www.instagram.com/mohammad.tbeishat/",
              icon: <IconInstagram />,
            },
            {
              name: "LinkedIn",
              url: "https://www.linkedin.com/in/mohammad-tbeishat",
              icon: <IconLinkedin />,
            },
          ]}
          className="justify-center md:justify-end"
        />
      </Container>
    </footer>
  );
};

export { Footer };
