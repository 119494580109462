"use client";

import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";

interface LogoProps {
  hideName?: boolean;
}

const Logo = ({ hideName }: LogoProps) => {
  return (
    <Link href="/" passHref>
      <motion.div
        className="group relative flex items-center gap-4 whitespace-nowrap text-xl font-bold transition duration-300 hover:transition"
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: -10 }}
      >
        <Image
          src="/assets/profile/mohammad.jpg"
          width={48}
          height={48}
          quality={100}
          alt="Vectorised image of Mohammad Altbishat"
          className="h-12 w-12 rounded-full"
        />
        {!hideName && (
          <>
            <span className="opacity-100 text-slate-300 from-blue-400 to-blue-700 transition duration-300 group-hover:bg-gradient-to-r group-hover:bg-clip-text group-hover:text-transparent group-hover:opacity-100">
              Mohammad Altbishat
            </span>
          </>
        )}
      </motion.div>
    </Link>
  );
};

export { Logo };
