import * as React from "react";
import { SVGProps } from "react";

interface SVGRProps {
  title?: string;
  titleId?: string;
}
export const IllustrationAccessibilityAudit = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 900 600"
    width="1em"
    height="1em"
    focusable="false"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#78909C"
      d="M771.056 487.291H127.944c-13.754 0-24.944-11.19-24.944-24.944v-77.403h693v77.403c0 13.754-11.189 24.944-24.944 24.944Z"
    />
    <path
      fill="#78909C"
      d="M532.734 474.343H368.732v117.144h164.002V474.343Z"
    />
    <path fill="#78909C" d="M542.599 576.69H358.867v14.797h183.732V576.69Z" />
    <path
      fill="#37474F"
      d="M795.383 417.621H103V24.118C103 14.679 110.679 7 120.118 7h658.148c9.438 0 17.117 7.68 17.117 17.118V417.62Z"
    />
    <path fill="#fff" d="M778.736 24.88H119.03v371.162h659.706V24.88Z" />
    <path
      fill="#FFCCBC"
      d="M246.79 246.102c5.049-4.172 11.295-4.949 13.95-1.735 2.654 3.214.713 9.2-4.339 13.373a16.388 16.388 0 0 1-6.917 3.477l-21.617 17.407-7.953-10.318 22.155-16.065a16.4 16.4 0 0 1 4.721-6.139Z"
    />
    <path
      fill="#1976D2"
      d="M121.846 285.378c-.011-.77-.004-7.485 4.967-11.729 5.711-4.876 13.769-2.903 16.596-2.21 6.528 1.598 7.064 4.399 13.195 7.277 11.471 5.384-2.902 2.628 3.924.902 2.363-.598 36.353-2.955 44.813-8.163 16.456-10.127 27.667-16.269 27.667-16.269l7.33 14.659s-10.598 14.737-25.515 25.654c-15.301 11.199-22.951 16.798-34.578 18.962-1.001.186-25.625 4.39-45.458-10.427-4.524-3.38-12.812-9.572-12.941-18.656Z"
    />
    <path
      fill="#FFCCBC"
      d="M144.582 248.097c14.101 0 25.533-11.432 25.533-25.533 0-14.102-11.432-25.533-25.533-25.533s-25.533 11.431-25.533 25.533c0 14.101 11.432 25.533 25.533 25.533Zm199.964 146.007-26.295-26.916-13.574 14.159 19.591 25.808 19.432 2.222.846-15.273Z"
    />
    <path
      fill="#263238"
      d="M194.398 343.63c1.538.127 6.426 1.107 11.098-1.464 3.188-1.754 4.823-4.362 5.375-5.191 6.852-10.29 33.149-26.336 56.75-27.324 5.344-.224 8.862.396 12.06 2.063 4.534 2.363 6.344 5.748 12.097 13.679 3.148 4.338 6.63 8.425 9.815 12.736 18.648 25.235 25.717 27.216 24.995 35.306-.802 8.976-10.483 17.493-16.917 16.509-2.635-.402-3.656-2.234-7.176-5.944-10.323-10.875-14.593-8.866-21.942-17.196-8.581-9.726-7.457-17.79-11.934-18.101-4.151-.289-5.915 6.59-14.778 15.729-4.055 4.182-6.943 6.1-15.741 12.203-34.261 23.769-34.808 25.439-41.722 27.335-3.025.829-24.496 6.716-32.145-2.652-11.969-14.659 16.307-58.84 30.165-57.688Zm142.109 56.556c1.934-.868 4.195-1.883 4.923-4.218.492-1.58.209-3.528-.809-4.875-1.174-1.553-2.912-1.758-2.841-2.032.107-.419 4.216-.255 8.727 1.339.851.301 4.707 1.71 9.613 5.54 3.93 3.068 3.401 3.683 5.866 5.453 7.565 5.431 14.661 1.15 18.755 6.243 1.579 1.966 2.253 4.757 1.588 7.013-1.335 4.532-7.828 6.091-11.377 6.896-8.546 1.939-15.015.356-21.77-.782-22.972-3.871-35.878-.345-37.606-5.667-.327-1.007.204-.921 1.975-8.313 1.749-7.295 1.691-9.299 3.18-9.883 2.241-.879 3.746 3.121 9.392 4.49 5.003 1.213 9.131-.641 10.384-1.204Z"
    />
    <path
      fill="#FFCCBC"
      d="m297.798 482.158-6.644-37.038-19.183 4.094 1.719 32.356 14.831 12.751 9.277-12.163Z"
    />
    <path
      fill="#263238"
      d="M201.901 356.079c1.201.97 4.695 4.524 10.004 5.021 3.623.339 6.44-.901 7.363-1.277 11.448-4.668 42.218-3.178 62.301 9.258 4.548 2.816 7.111 5.304 8.821 8.478 2.424 4.502 2.021 8.32 2.328 18.113.169 5.357.755 10.694.97 16.049 1.26 31.353 5.997 36.961.856 43.25-5.704 6.977-18.496 8.588-23.268 4.161-1.955-1.813-1.77-3.902-2.6-8.948-2.435-14.795-7.097-15.531-8.5-26.55-1.638-12.866 3.819-18.908.29-21.679-3.273-2.57-8.596 2.131-21.061 4.716-5.703 1.183-9.17 1.149-19.877 1.259-41.697.428-43.087 1.503-49.873-.811-2.969-1.012-24.04-8.198-25.109-20.244-1.672-18.851 46.534-39.531 57.355-30.796Zm85.829 126.598c2.088.367 4.529.797 6.442-.727 1.295-1.031 2.154-2.802 2.068-4.487-.099-1.945-1.422-3.09-1.209-3.278.323-.286 3.631 2.157 6.469 6.009.535.727 2.935 4.059 4.843 9.982 1.529 4.746.746 4.958 1.792 7.806 3.211 8.742 11.485 9.185 12.013 15.698.203 2.513-.806 5.201-2.624 6.695-3.649 3-9.898.644-13.286-.683-8.161-3.194-12.624-8.137-17.575-12.872-16.834-16.102-29.493-20.433-27.934-25.806.295-1.017.686-.648 6.302-5.77 5.543-5.054 6.621-6.744 8.181-6.392 2.348.531 1.347 4.686 5.25 8.989 3.458 3.813 7.915 4.598 9.268 4.836ZM148.063 227.445c.994-3.556-.254-4.851 1.018-6.447 0-.001.512-.643 5.09-2.376-1.35-7.837-.339-9.162-.339-9.162 1.166-1.527 3.501-1.897 5.09-1.697 3.362.423 3.957 3.462 6.108 3.394 2.972-.095 6.536-6.043 5.43-11.199-.903-4.205-4.794-7.329-5.769-6.787-.967.538-4.448-1.832-5.769-1.357-.901.324-5.146-.107-6.108.339-1.051.488-4.061 3.524-4.348 3.141-2-2.658-1.957-3.586-3.118-4.159-2.055-1.015-4.29.861-8.144 2.715-7.929 3.814-10.251 1.539-13.913 4.751-1.85 1.622-2.842 4.723-4.751 10.859-2.669 8.58-4.004 12.871-2.715 16.967 1.315 4.178 3.251 3.268 6.109 9.162 3.181 6.561 2.084 10.375 5.09 11.877 2.323 1.162 6.056.423 7.465-1.696 2.414-3.628-3.824-8.536-1.696-14.253 1.306-3.509 5.334-6.17 7.805-5.429 2.793.838 2.999 5.846 4.75 5.769 1.501-.067 2.542-3.792 2.715-4.412Z"
    />
    <path
      fill="#1976D2"
      d="M128.72 251.878c5.225-5.859 26.508.205 28.505 9.501.822 3.824-2.211 5.439-1.357 9.841 1.249 6.44 8.323 6.001 13.574 12.556 7.454 9.307-.225 18.404 8.144 33.935 1.788 3.318 3.296 6.116 6.448 8.823 7.232 6.212 13.963 4.081 17.645 9.841 2.754 4.307 1.623 9.615 1.358 10.859-1.087 5.099-4.033 6.341-6.108 10.519-3.454 6.952.456 12.055.678 20.361.278 10.392-5.244 24.797-16.288 29.523-12.408 5.31-27.372-3.485-34.613-12.216-4.321-5.211-5.576-10.017-7.466-15.95-8.448-26.513-13.221-24.115-19.682-45.132-7.386-24.027-8.838-52.175 0-61.082 1.172-1.181 4.395-3.91 6.448-8.823 2.761-6.612.376-9.934 2.714-12.556Z"
    />
    <path
      fill="#FFCCBC"
      d="M205.924 388.254c4.051 5.147 4.679 11.409 1.403 13.986-3.276 2.578-9.214.495-13.266-4.655a16.385 16.385 0 0 1-3.311-6.998l-16.889-22.024 8.727-6.657 17.311 21.482a16.4 16.4 0 0 1 6.025 4.866Z"
    />
    <path
      fill="#1976D2"
      d="M144.072 274.205c.752-.168 7.327-1.531 12.496 2.47 5.939 4.595 5.651 12.887 5.55 15.795-.233 6.717-2.866 7.813-4.432 14.403-2.93 12.328 2.65 23.785 5.733 30.115 1.067 2.191 4.496 8.895 11.32 16.115 13.272 14.042 25.114 21.432 25.114 21.432l-12.722 11.308s-20.264-6.177-33.996-18.552c-14.085-12.694-35.745-57.606-40.235-68.547-.387-.942 5.09 14.376 15.549-8.063 2.386-5.118 6.757-14.496 15.623-16.476Z"
    />
    <path
      fill="#37474F"
      d="m469.68 263.67-15.19-7.196c6.23 8.695 11.598 22.274 14.472 33.08 4.867-10.066 12.711-22.381 20.476-29.738l-16.054 4.13c9.892-48.482 47.1-83.287 89.708-83.287l.603-1.752c-44.505 0-83.915 34.323-94.015 84.763Z"
    />
    <path
      fill="#1976D2"
      d="M539.592 337.541h-95.975c-4.071 0-7.383-3.312-7.383-7.382 0-4.071 3.312-7.383 7.383-7.383h95.975c4.071 0 7.383 3.312 7.383 7.383 0 4.07-3.312 7.382-7.383 7.382Z"
    />
    <path
      fill="#78909C"
      d="M667.3 337.541h-95.976c-4.07 0-7.382-3.312-7.382-7.382 0-4.071 3.312-7.383 7.382-7.383H667.3c4.07 0 7.382 3.312 7.382 7.383 0 4.07-3.312 7.382-7.382 7.382Z"
    />
    <path
      fill="#37474F"
      d="M610.483 136.701h-93.292a4.271 4.271 0 0 1-4.266-4.266 4.27 4.27 0 0 1 4.266-4.264h93.292a4.269 4.269 0 0 1 4.265 4.264 4.27 4.27 0 0 1-4.265 4.266Zm50.062 45.508H467.129a4.271 4.271 0 0 1-4.266-4.266 4.27 4.27 0 0 1 4.266-4.265h193.416a4.27 4.27 0 0 1 4.265 4.265 4.27 4.27 0 0 1-4.265 4.266ZM145 592.5c0 .832.615 1.5 1.381 1.5h606.238c.766 0 1.381-.668 1.381-1.5s-.615-1.5-1.381-1.5H146.381c-.766 0-1.381.668-1.381 1.5ZM293.594 265c-.165 0-.329-.006-.495-.016-9.755-.623-43.132-5.318-63.058-39.567-1.642-2.82-1.318-6.405.805-8.92l36.902-43.707c1.509-1.788 3.84-2.818 6.201-2.789 1.632.038 5.495.448 6.246 3.409 4.432 17.492 23.151 38.059 45.663 40.785 2.282.276 4.114 2.299 4.9 5.413a7.681 7.681 0 0 1-1.555 6.855l-29.562 35.685a7.832 7.832 0 0 1-6.047 2.852Zm-19.787-93.241c-1.803 0-3.557.803-4.704 2.161l-36.901 43.707c-1.647 1.951-1.899 4.727-.629 6.909 19.486 33.494 52.107 38.083 61.64 38.692a6.046 6.046 0 0 0 5.062-2.198l29.562-35.685a5.95 5.95 0 0 0 1.205-5.308c-.464-1.838-1.6-3.878-3.398-4.097-23.256-2.815-42.59-24.045-47.165-42.101-.371-1.462-2.602-2.033-4.567-2.079l-.105-.001Z"
    />
    <path
      fill="#78909C"
      d="m252.477 226.401 26.932-26.967c1.841-1.843-1.043-4.68-2.886-2.835l-26.932 26.967c-1.841 1.843 1.043 4.68 2.886 2.835Zm16.056 8.063a105.128 105.128 0 0 0 19.147-22.491c1.393-2.2-2.131-4.22-3.519-2.029-5.104 8.058-11.337 15.336-18.509 21.678-1.957 1.73.933 4.565 2.881 2.842Zm14.779 8.9a110.237 110.237 0 0 0 16.459-24.337c1.135-2.289-2.261-4.313-3.4-2.015-4.226 8.527-9.535 16.446-15.843 23.53-1.694 1.902 1.082 4.734 2.784 2.822Z"
    />
    <path
      fill="#1976D2"
      d="M271.856 194.944c14.756 0 26.717-11.709 26.717-26.154 0-14.444-11.961-26.154-26.717-26.154-14.755 0-26.717 11.71-26.717 26.154 0 14.445 11.962 26.154 26.717 26.154Z"
    />
    <path
      fill="#fff"
      d="m285.758 181.197-12.327-30.308c-.568-1.395-2.346-1.444-2.899 0-4.06 10.592-6.713 21.644-7.989 32.912-.217 1.92 2.791 1.904 3.007 0 .499-4.409 1.24-8.786 2.191-13.116 3.386.178 6.8-.202 10.067-1.107l5.051 12.418c.719 1.769 3.629.995 2.899-.799Zm-17.335-13.484a132.48 132.48 0 0 1 3.635-12.271l4.608 11.329a29.063 29.063 0 0 1-8.243.942Z"
    />
  </svg>
);
