import * as React from "react";
import { SVGProps } from "react";

interface SVGRProps {
  title?: string;
  titleId?: string;
}
export const IllustrationEcommerce = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 900 600"
    width="1em"
    height="1em"
    focusable="false"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#78909C"
      d="M789.307 10.686 57.374 5.416 54 474.178l731.933 5.269 3.374-468.761Z"
    />
    <path
      fill="#fff"
      d="M767.955 69.33 77.881 64.361l-2.752 382.235 690.074 4.968 2.752-382.236Z"
    />
    <path
      fill="#1976D2"
      d="M788.998 10.269 57.065 5l-.223 31.095 731.933 5.269.223-31.095Z"
    />
    <path
      fill="#fff"
      d="M80.018 26.828a5.764 5.764 0 1 0 .083-11.527 5.764 5.764 0 0 0-.083 11.527Zm21.875.158a5.764 5.764 0 1 0 .083-11.528 5.764 5.764 0 0 0-.083 11.528Zm21.875.157a5.764 5.764 0 1 0 .084-11.528 5.764 5.764 0 0 0-.084 11.528Z"
    />
    <path
      fill="#78909C"
      d="m297.528 97.384-134.968-.971-1.054 146.428 134.968.972 1.054-146.429Zm192.646 1.387L355.206 97.8l-1.054 146.428 134.968.972 1.054-146.429Zm192.646 1.387-134.968-.972-1.055 146.429 134.969.972 1.054-146.429ZM296.283 270.317l-134.968-.972-1.054 146.429 134.968.971 1.054-146.428Zm192.646 1.387-134.968-.972-1.054 146.429 134.968.971 1.054-146.428Zm192.646 1.387-134.969-.972-1.054 146.428 134.968.972 1.055-146.428Z"
    />
    <path
      fill="#fff"
      d="m386.312 308.6 24.731-9.572 2.516 48.767s5.054 14.948 3.293 20.671c0 0 1.076 9.758-1.796 10.311-2.871.552-10.911 1.642-11.474 1.064-.564-.579-.557-2.299-.557-2.299s-5.764 3.973-6.37 8.557c0 0-24.141 7.282-24.665.396-.524-6.887 12.134-12.531 12.134-12.531l9.857-14.841-7.669-50.523Zm52.19.375 24.727-9.572 2.517 48.768s5.054 14.948 3.292 20.671c0 0 1.077 9.758-1.795 10.31-2.872.553-10.911 1.643-11.474 1.064-.564-.578-.557-2.298-.557-2.298s-5.764 3.973-6.371 8.556c0 0-24.14 7.282-24.664.396-.524-6.886 12.134-12.531 12.134-12.531l9.857-14.84-7.666-50.524Z"
    />
    <path
      fill="#37474F"
      d="M442.267 120.749s-5.356 14.635.68 21.295l-5.198 17.393s17.632 54.193 12.429 61.708c0 0-19.291 9.473-57.646-3.848l16.054-60.474-2.047-25.589 3.175-11.992 6.87-.632s-4.23 15.417 3.305 17.875c7.534 2.458 15.044-17.356 15.044-17.356l7.334 1.62Z"
    />
    <path
      fill="#fff"
      d="m199.091 213.715 6.607 2.759 31.926-41.338 9.588 48.865 6.635-1.157c4.553-42.036 8.332-84.918 4.222-105.243l-24.541-1.231-11.399 35.466-5.596 24.057-17.442 37.822Zm402.873-93.971s-9.134 12.507-8.729 17.583c.405 5.077 5.544 87.831 5.544 87.831l13.45.759-3.545-59.582 3.425-16.078 7.621 75.052 16.54.561-14.077-97.377-2.806-8.623-17.423-.126Z"
    />
    <path
      fill="#37474F"
      d="M651.618 322.65c-2.381-2.899-6.497-4.486-10.454-5.357.116-.359-12.034-2.961-12.244-2.445l-4.562-3.688-14.149 7.218-7.882-5.45-3.689 1.28c.155-.649-11.431 1.273-11.431 1.273-2.211.063-5.036.182-8.198.402-11.074.772-12.133 29.431-12.133 29.431a45.256 45.256 0 0 1 16.047-3.24l2.744 34.307c15.396-1.208 31.521.443 48.22 4.321l8.038-26.895 18.316-6.972s-2.996-17.334-8.623-24.185Z"
    />
    <path
      fill="#fff"
      d="M259.775 303.502c-1.961-2.388-5.352-3.697-8.613-4.414.099-.297-9.916-2.44-10.09-2.014l-3.759-3.038-11.656 5.947-6.495-4.489-3.039 1.054c.128-.534-9.419 1.048-9.419 1.048-1.817.053-4.15.15-6.754.332-9.125.636-9.996 24.248-9.996 24.248a37.271 37.271 0 0 1 13.221-2.669l1.967 69.171c12.686-.997 25.973.365 39.728 3.56l6.916-63.063 15.093-5.744s-2.468-14.282-7.104-19.929Zm294.621 137.775 1.222 88.265 56.915 30.604 104.178-17.831 3.216-91.718-65.79-23.7-99.741 14.38Z"
    />
    <path
      fill="#78909C"
      d="m612.377 561.098-57.664-31.006-1.241-89.604 100.76-14.526.214.077 66.416 23.925-3.264 93.125-105.221 18.009Zm-55.854-32.105 56.166 30.2 103.137-17.652 3.166-90.311-64.95-23.397-98.723 14.232 1.204 86.928Z"
    />
    <path
      fill="#78909C"
      d="m611.939 465.62-.223-.09-57.663-23.409.685-1.69 57.441 23.318 107.63-14.056.237 1.809-108.107 14.118Z"
    />
    <path
      fill="#78909C"
      d="m612.972 464.68-1.825.009.474 95.461 1.825-.009-.474-95.461Zm-13.343-5.794-15.75-6.487 100.467-15.268 15.749 6.487-100.466 15.268Zm-45.057 7.994-.734 1.671 24.102 10.584.734-1.671-24.102-10.584Z"
    />
    <path
      fill="#1976D2"
      d="m565.303 497.066-.733 1.671 24.102 10.584.734-1.671-24.103-10.584Zm88.26-42.975 1.42 102.463 66.068 35.525 120.935-20.698 3.732-106.47-76.372-27.511-115.783 16.691Z"
    />
    <path
      fill="#37474F"
      d="m720.87 593.184-66.938-35.993-1.441-104.015 116.966-16.862.248.089 77.098 27.773-3.789 108.103-122.144 20.905Zm-64.838-37.268 65.199 35.058 119.726-20.492 3.675-104.836-75.396-27.16-114.601 16.521 1.397 100.909Z"
    />
    <path
      fill="#37474F"
      d="m720.361 482.351-.258-.105-66.939-27.174.797-1.962 66.679 27.068 124.94-16.317.275 2.1-125.494 16.39Z"
    />
    <path
      fill="#37474F"
      d="m721.56 481.259-2.119.011.551 110.814 2.118-.011-.55-110.814Zm-14.415-6.67-19.357-7.586 116.625-17.724 19.358 7.586-116.626 17.724Zm-53.377 9.224-.852 1.939 27.979 12.286.851-1.939-27.978-12.286Z"
    />
    <path
      fill="#fff"
      d="m666.225 518.854-.852 1.939 27.979 12.286.852-1.939-27.979-12.286Z"
    />
  </svg>
);
